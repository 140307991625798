import React from 'react';

import Button from '../../controls/Button';
import HtmlBlock from '../../utilities/HtmlBlock';
import HeaderAndImage from './HeaderAndImage';

import './index.scss';

const Hero = props => {

	let classes = 'hero';
	classes += props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';
	if (props.ctas) classes += ' with-ctas';

	return (
		<div id={props.id} className={classes}>

			<HeaderAndImage {...props} />

			<div className="divider">
				<div className="wrap">
					<div className="row">
						<div className="col">
							<hr />
						</div>
					</div>
				</div>
			</div>
			{ (props.intro || props.ctas.length > 0) &&
				<div className="intro">
					<div className="row">
						<HtmlBlock content={props.intro} />
						{props.ctas &&
							<ul className="cta-list">
								{props.ctas.map((cta, i) => {
									return (
										<li key={i}>
											<Button {...cta.button} url={cta.button.linkType === 'page' ? cta.button.pageUrl : cta.button.customUrl}>
												{cta.button.label}
											</Button>
										</li>
									)
								})}
							</ul>
						}
					</div>
				</div>
			}
		</div>
	)
}

export default Hero;
