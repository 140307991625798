import React from 'react';
import PropTypes from 'prop-types';

const boolNum = (value) => { return (value ? 1 : 0); };

const Vimeo = (props) => {
  const {
    videoSrc,
    autoplay,
    muted,
    loop,
    showControls,
    videoTitle,
  } = props;

  const params = [
    `autoplay=${boolNum(autoplay)}`,
    `muted=${boolNum(muted)}`,
    `controls=${boolNum(showControls)}`,
    `loop=${boolNum(loop)}`,
  ].filter(Boolean).join('&');

  const videoUrl = `https://player.vimeo.com/video/${videoSrc}?${params}`;

  return (
    <iframe
      className="iframe"
      title={videoTitle}
      width="16"
      height="9"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      src={videoUrl}
      controls="1"
    />
  );
};

Vimeo.defaultProps = {
  videoSrc: '',
  autoplay: false,
  muted: false,
  loop: false,
  showControls: true,
  videoTitle: '',
};

Vimeo.propTypes = {
  videoSrc: PropTypes.string,
  autoplay: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  showControls: PropTypes.bool,
  videoTitle: PropTypes.string,
};

export default Vimeo;
