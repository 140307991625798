import React from 'react';
import './index.scss';
import HtmlBlock from '../../utilities/HtmlBlock';
import Quote from '../../graphics/Quote';

const Blockquote = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';

	return (
		<div id={props.id} className={`blockquote${margin}`}>
			<div className="blockquote-wrap">
				<blockquote>
					<Quote />
					<HtmlBlock content={props.blockquote.quote} />
				</blockquote>
				<cite>
					<HtmlBlock content={`&mdash;&nbsp;${props.blockquote.quotee}`} />
				</cite>
			</div>
			<img className="quotee-image" src={props.blockquote.optionalImage.url} alt="" />
		</div>
	);
}

export default Blockquote;
