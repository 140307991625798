import React from 'react';
import PropTypes from 'prop-types';
import Video from '../../elements/Video';
import { videoPropFormatter } from '../../elements/Video/helpers';

import './index.scss';

const baseName = 'fullwidth-video';

const FullwidthVideo = (props) => {
  const {
    className,
    embeddedVideo,
    margin,
    id,
  } = props;

  const classes = [
    baseName,
    className,
    'content-block',
    margin && `margin-${margin}`,
  ].filter(Boolean).join(' ');

  const videoProps = videoPropFormatter(embeddedVideo);

  return (
    <div className={classes}>
      <div className="row">
        <div key={id} className="column col12">
          <Video {...videoProps} />
        </div>
      </div>
    </div>
  );
};

FullwidthVideo.defaultProps = {
  className: '',
  margin: 'none',
  id: '',
};

FullwidthVideo.propTypes = {
  className: PropTypes.string,
  embeddedVideo: PropTypes.shape({
    autoplayMute: PropTypes.bool,
    customAspectRatio: PropTypes.string,
    loop: PropTypes.bool,
    player: PropTypes.string,
    poster: PropTypes.bool,
    showControls: PropTypes.bool,
    videoFile: PropTypes.shape({
      url: PropTypes.string,
    }),
    videoTitle: PropTypes.string,
    vimeoId: PropTypes.string,
    youtubeId: PropTypes.string,
  }).isRequired,
  margin: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
  id: PropTypes.string,
};

export default FullwidthVideo;
