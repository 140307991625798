import Default from './Default';
import Blog from './Blog';
import Post from './Post';

const templates = {
	default: Default,
	blog: Blog,
	post: Post,
}

export default templates;
