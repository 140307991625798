import React from 'react';
import { connect } from 'react-redux';

import FlexibleContent from '../../utilities/FlexibleContent';
import EmbeddedImage from '../../elements/EmbeddedImage';
import CategoriesList from '../../elements/CategoriesList';

import formatDate from '../../../utilities/formatDate';

import './index.scss';

const mapStateToProps = state => ({
	windowWidth: state.browser.windowWidth
})

const Post = props => {
	return (
		<article className={`${props.slug} template-post`}>
			<div className="post-header">
				<EmbeddedImage className="header-image" image={props.data['_embedded']} size={props.windowWidth > 600 ? 'header' : 'card' } />
				<div className="header-content">
					<div className="header-meta">
						<span className="header-date">{formatDate(props.data.date)}</span>
						<CategoriesList data={props.data['_embedded']} />
					</div>
					<h1 className="header-title">{ props.data.title.rendered }</h1>
					<hr className="header-delimiter" />
				</div>
			</div>
			<FlexibleContent content={props.data.acf.blocks} />
		</article>
	)
}

export default connect(mapStateToProps)(Post);
