import React from 'react';

import HtmlBlock from '../../utilities/HtmlBlock';
import Button from '../../controls/Button';

import './index.scss';

const Content = (props) => {
  const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';

  return (

    <div id={props.id} className={`content-block${margin}`}>
      <div className="row">
        {props.row
					&& props.row.map((column, i) => {
					  const url = column.optionalButton.linkType === 'page' ? column.optionalButton.pageUrl : column.optionalButton.customUrl;
					  const secondaryButtonUrl = column.optionalSecondaryButton.linkType === 'page' ? column.optionalSecondaryButton.pageUrl : column.optionalSecondaryButton.customUrl;
					  const hasButton = column.optionalButton.show;
					  const hasSecondaryButton = column.optionalSecondaryButton.show;
					  const padding = column.padding ? ` padding-${column.padding}` : '';

					  return (
  <div className={`column ${column.width}${padding}${hasButton ? ' has-button' : ''}`} key={i}>
    <HtmlBlock content={column.content} />
    {hasButton
									&& (
<Button url={url} type={column.optionalButton.type} icon={column.optionalButton.icon} arrow={column.optionalButton.arrow}>
  {column.optionalButton.label}
</Button>
									)
								}
    {hasSecondaryButton
									&& (
<Button url={secondaryButtonUrl} type={column.optionalSecondaryButton.type} icon={column.optionalSecondaryButton.icon} arrow={column.optionalSecondaryButton.arrow}>
  {column.optionalSecondaryButton.label}
</Button>
									)
								}
  </div>
					  );
					})
				}
      </div>
    </div>
  );
};

export default Content;
