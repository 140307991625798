import React from 'react';

const Icon = props => {
	return (
		<svg className="graphic graphic-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.24 100">
			<path className="fill" style={{ fill: props.color }} d="M95.89,100H0L52.74,37H81.51L51.71,77.05H73L88.27,27.53s2-5.1-3.57-5.1H52.33L62.66,0h46.76s15.3,0,11.09,14.4Z" />
		</svg>
	)
}

export default Icon;
