import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './index.scss';

const mapStateToProps = state => ({
  windowWidth: state.browser.windowWidth,
});

class ImageTiles extends Component {
  constructor() {
    super();

    this.state = {
      height: 300,
      width: 600,
    };

    this.FeaturedTileRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { windowWidth } = this.props;
    if (prevProps.windowWidth !== windowWidth) {
      this.setImageSize();
    }
  }

  setImageSize = () => {
    const { offsetHeight: height, offsetWidth: width } = this.FeaturedTileRef.current;
    this.setState({ height, width });
  }

  render() {
    const { height, width } = this.state;
    const { imageUrls } = this.props;
    const [firstTileUrl, secondTileUrl, thirdTileUrl] = imageUrls;

    return (
      <Fragment>
        <div
          className="third-image tile"
          style={{
            height: `${height}px`,
            width: `${width}px`,
            left: `${width * 0.5}px`,
            top: `${height * 0.25}px`,
            backgroundImage: `url(${thirdTileUrl})`,
          }}
        />
        <div
          className="second-image tile"
          style={{
            height: `${height}px`,
            width: `${width}px`,
            left: `${width * 0.25}px`,
            bottom: `${height * 0.33}px`,
            backgroundImage: `url(${secondTileUrl})`,
          }}
        />
        <img className="first-image tile" src={firstTileUrl} alt="" ref={this.FeaturedTileRef} onLoad={this.setImageSize} />
      </Fragment>
    );
  }
}

ImageTiles.propTypes = {
  imageUrls: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ImageTiles);
