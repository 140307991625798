const defaultState = {
  scrollPos: 0,
  windowWidth: 0,
  contentWidth: 0,
  frameWidth: 0,
  windowHeight: 700,
  scrollPercentage: 0,
  headerHeight: 0,
  searchParams: {},
  sidebarOpen: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_SCROLL':

      return {
        ...state,
        scrollPos: action.payload,
      };

    case 'UPDATE_SCROLL_PERCENTAGE':

      return {
        ...state,
        scrollPercentage: action.payload,
      };

    case 'SET_WINDOW_HEIGHT':

      return {
        ...state,
        windowHeight: action.payload,
      };

    case 'SET_WINDOW_CONTENT_WIDTHS':

      return {
        ...state,
        windowWidth: action.payload.window,
        frameWidth: action.payload.frame,
        contentWidth: action.payload.content,
      };

    case 'SET_HEADER_HEIGHT':

      return {
        ...state,
        headerHeight: action.payload,
      };

    case 'SET_SEARCH_PARAMS':

      return {
        ...state,
        searchParams: action.payload,
      };

    case 'TOGGLE_SIDEBAR':

      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };

    default:
    //
  }

  return state;
};
