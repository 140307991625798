import React from 'react';

const Quote = props => {
	return (
		<svg className="graphic quotation-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
			<polygon className="fill" points="8.8,0 8.8,37.3 30.1,48 39.2,48 27,37.3 27,26.7 39.2,26.7 39.2,0 " />
		</svg>
	)
}

export default Quote;