import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Video from '../../elements/Video';
import { useBackgroundVideo } from '../../providers/BackgroundVideoProvider';

import './index.scss';

const mapStateToProps = state => ({
  bgVideo: state.api.bgVideo,
});

const BackgroundVideo = ({ bgVideo }) => {
  const { pageBackgroundInfo } = useBackgroundVideo();

  return (
    <React.Fragment>
      {bgVideo?.video && (
        <Video
          key={bgVideo.video.url}
          className="bg"
          videoSrc={bgVideo.video.url}
          htmlVideoAttributes={{ autoPlay: true, loop: true, muted: true, playsInline: true }}
          poster={bgVideo.image.url}
          customAspectRatio={false}
        />
      )}

      {pageBackgroundInfo?.video && (
        <Video
          key={pageBackgroundInfo.video.url}
          className="bg"
          videoSrc={pageBackgroundInfo.video.url}
          htmlVideoAttributes={{ autoPlay: true, loop: true, muted: true, playsInline: true }}
          poster={pageBackgroundInfo.image.url}
          customAspectRatio={false}
        />
      )}
    </React.Fragment>
  )
};

BackgroundVideo.defaultProps = {
  bgVideo: {},
};

BackgroundVideo.propTypes = {
  bgVideo: PropTypes.shape({
    video: PropTypes.shape({
      url: PropTypes.string,
    }),
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

export default connect(mapStateToProps)(BackgroundVideo);
