import React, { Component } from 'react';
import Slick from 'react-slick';

import HtmlBlock from '../../utilities/HtmlBlock';
import Chevron from '../../graphics/Chevron';

import './index.scss';

class Slider extends Component {
	constructor() {
		super();

		this.sliderRef = React.createRef();
	}

	render() {

		const sliderSettings = {
			arrows: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			ref: this.sliderRef,
			responsive: [{
				breakpoint: 850,
				settings: {
					slidesToShow: 2
				}
			}, {
				breakpoint: 600,
				settings: {
					slidesToShow: 1
				}
			}]
		}

		const margin = this.props.margin && this.props.margin !== 'none' ? ` margin-${this.props.margin}` : '';
		const alignment = this.props.alignment ? ` align-${this.props.alignment}` : '';

		if (this.props.slides) {
			return (
				<div className={`content-slider${margin}${alignment}`} id={this.props.id}>
					<div className="slider-inner-wrapper">
						<div className="slider-intro">
							<h2 className="slider-title">{this.props.title}</h2>
							{this.props.slides.length > 2 &&
								<nav>
									<button onClick={() => this.sliderRef.current.slickPrev()}><Chevron /></button>
									<button onClick={() => this.sliderRef.current.slickNext()}><Chevron /></button>
								</nav>
							}
						</div>
						<div className="slider-wrapper">
							<Slick {...sliderSettings}>
								{this.props.slides.map((slide, index) => {
									return (
										<React.Fragment key={index}>
											{slide.label &&
												<p className="label">{slide.label}</p>
											}
											{slide.content &&
												<HtmlBlock content={slide.content} />
											}
										</React.Fragment>
									)
								})}
							</Slick>
						</div>
					</div>
				</div>
			)
		}

		return null;
	}
}

export default Slider;
