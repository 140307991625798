import React from 'react';

export default React.forwardRef((props, ref) => {

	const baseClasses = 'html-block';
	const classList = props.className ? `${baseClasses} ${props.className}` : baseClasses;

	if (props.content) {
		return (
			<div id={props.id} ref={ref} className={classList} suppressHydrationWarning
				dangerouslySetInnerHTML={{ __html: props.content || "No HTML" }}
			/>
		)
	}
	return null;
})
