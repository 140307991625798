export default (embedded, taxonomy) => {

	const terms = embedded && embedded['wp:term'] ? embedded['wp:term'] : false;

	if (terms) {
		return terms.reduce(( accumulator, term ) =>
			accumulator.concat(term.filter(item => item.taxonomy === taxonomy)),
		[]);
	}

	return false;
}