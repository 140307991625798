import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import AnimateHeight from 'react-animate-height';

import Chevron from '../../graphics/Chevron';
// import smoothScroll from '../../../utilities/smoothScroll';

import './index.scss';

class Drawer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			height: this.props.open ? 'auto' : 0,
			params: {}
		}

		this.className = 'drawer';
		this.className += this.props.className ? ` ${this.props.className}` : '';
	}

	toggle = () => {
		const { height } = this.state;

		if (this.props.updatesUrl) {
			let newParams = { ...this.state.params };;

			// If drawer is currently closed
			if (height === 0) {
				if (typeof newParams.drawer === 'object') {
					newParams.drawer.push(this.props.id);
				} else {
					newParams.drawer = [this.props.id];
				}
			} else {
				// remove the param
				if (newParams.drawer) {
					if (typeof newParams.drawer === 'string') {
						delete newParams.drawer;
					}

					if (typeof newParams.drawer === 'object') {
						newParams.drawer = newParams.drawer.filter(param => param !== this.props.id);
					}
				}
			}

			this.props.history.push({
				search: queryString.stringify(newParams)
			})
		}

		this.setState({
			height: height === 0 ? 'auto' : 0,
		});

		if (this.props.onClick) { this.props.onClick(); }
	}

	storeParams = () => {
		this.setState({
			params: queryString.parse(
				this.props.location.search,
				{ ignoreQueryPrefix: true }
			)
		}, () => {
			this.checkIfOpen();
		});
	}

	checkIfOpen = () => {
		const { drawer } = this.state.params;

		if (drawer) {
			let open = false;
			if (typeof drawer === 'string' && drawer === this.props.id) {
				open = true;
			} else if (typeof drawer === 'object') {
				open = drawer.some(param => param === this.props.id);
			}

			if (open) {
				this.setState({
					height: 'auto'
				})
			}
		} else {
			this.setState({
				height: 0
			})
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {
			this.storeParams();
		}
	}

	componentDidMount() {
		this.storeParams();
	}

	render() {
		const { height } = this.state;

		return (
			<div id={this.props.id} className={`${this.className}${height === 0 ? '' : ' open'}`}>
				<header onClick={this.toggle}>
					<div className="wrap">
						{this.props.heading}
						<Chevron />
					</div>
				</header>
				<AnimateHeight
					className="content"
					duration={250}
					height={height} >
					{this.props.content}
				</AnimateHeight>
			</div>
		)
	}
}

export default withRouter(Drawer);
