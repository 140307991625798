import React from 'react';
import { Link } from 'react-router-dom';

import isLocalPath from '../../../utilities/isLocalPath';
import formatPermalink from '../../../utilities/formatPermalink';

import './index.scss';

const Hyperlink = props => {

	const classes = props.className ? `hyperlink ${props.className}` : 'hyperlink';

	const hyperlinkProps = {
		className: classes,
		style: props.style,
		target: props.target,
		onClick: props.onClick
	}

	if (props.url) {
		if (isLocalPath(props.url)) {
			const permalink = formatPermalink(props.url);
			return (
				<Link to={permalink} {...hyperlinkProps}>
					{props.children}
				</Link>
			)

		} else {
			return (
				<a {...hyperlinkProps} href={props.url} target="_blank" rel="noopener noreferrer">
					{props.children}
				</a>
			)
		}
	}

	return (
		<div {...hyperlinkProps}>
			{props.children}
		</div>
	)
}

export default Hyperlink;
