import React from 'react';

import './index.scss';

const ImageBlock = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';

	return (
		<div id={props.id} className={`image-block${margin}`}>
			<img src={props.image.url} alt={props.image.alt} />
		</div>
	)
}

export default ImageBlock;