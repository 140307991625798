import React from 'react';

import Drawer from '../../elements/Drawer';
import HtmlBlock from '../../utilities/HtmlBlock';

import './index.scss';

const Accordion = (props) => {
  const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';

  return (
    <div id={props.id} className={`accordion-block${margin}`}>
      <ul>
        {props.accordionBlock.map((drawer, i) => {
				  return (
  <li key={i}>
    <Drawer
      heading={<h5 dangerouslySetInnerHTML={{ __html: drawer.heading }} />}
      content={<HtmlBlock className="content" content={drawer.drawerContent} />
							}
    />
  </li>
				  );
        })}
      </ul>
    </div>
  );
};

export default Accordion;
