import React from 'react';

import Post from './Post';

import './index.scss';

const types = {
	'post': Post
}

const Card = props => {

	const CardType = types[props.type];

	if (CardType) {
		return <CardType className={ `card type-${props.type}` } data={ props.data } index={ props.index } />
	}
	return null;
}

export default Card;
