import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from '../../elements/Card';
import LoadContent from '../../utilities/LoadContent';
import Content from '../../blocks/Content';

import './index.scss';

const mapStateToProps = state => ({
	posts: state.api.data.posts
})

class Blog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			posts: []
		}
	}

	handleLoaded = posts => {
		this.setState({ posts })
	}

	render() {
		return (
			<article className={ `${this.props.slug} template-blog` }>
				{ this.props.data.acf.pageHeader &&
					<Content row={ this.props.data.acf.pageHeader.row } />
				}
				<div className="blog-archive-wrapper">
					<div className="row">
						{ this.state.posts.map((post, index) => {
							const postObject = this.props.posts[post.slug];
							return (
								<Card key={ index } type="post" data={ postObject } index={ index } />
							)
						}) }
					</div>
				</div>
				<LoadContent type="posts" onLoad={ this.handleLoaded } />
			</article>
		)
	}
}

export default connect(mapStateToProps)(Blog);
