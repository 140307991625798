import React from 'react';
import { connect } from 'react-redux';

import SocialMedia from '../../elements/SocialMedia';
import Hamburger from '../../graphics/Hamburger';
import Hyperlink from '../../controls/Hyperlink';

import './index.scss';

const mapStateToProps = (state) => ({
	mainMenu: state.api.menus.main,
	socialMediaLinks: state.api.socialMediaLinks,
	sidebarOpen: state.browser.sidebarOpen
})

const mapDispatchToProps = (dispatch) => ({
	toggleSidebar: () => dispatch({ type: 'TOGGLE_SIDEBAR' })
})

const Sidebar = props => {

	let sidebarClasses = 'sidebar';
	if (props.sidebarOpen) sidebarClasses += ' open';

	return (
		<div className={sidebarClasses}>
			<div className="sidebar-wrap">
				<button className="menu-btn" onClick={() => props.toggleSidebar()}>
					<Hamburger className={props.sidebarOpen ? 'active' : undefined} />
				</button>
				<nav className="main">
					{props.mainMenu &&
						props.mainMenu.items.map(item =>
							<Hyperlink key={item.ID} url={item.slug ? item.slug : item.url} className={item.post_parent ? 'has-parent' : undefined} onClick={() => props.toggleSidebar()}
							target={!item.slug ? '_blank' : undefined}>
								{item.title}
							</Hyperlink>
						)
					}
				</nav>
				<ul className="social-media-links">
					{props.socialMediaLinks.map((link, i) => {
						return (
							<li key={i}>
								<SocialMedia url={link.url} icon={link.icon} />
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
