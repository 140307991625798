import canUseDom from './canUseDom';

export default path => {
	let isLocal = false;

	// use lastIndexOf method and not startsWith which has zero IE support
	if (canUseDom) {
		if (path.lastIndexOf(window.location.origin, 0) === 0) {
			isLocal = true;
		}
	}

	if (path.lastIndexOf(process.env.REACT_APP_URL, 0) === 0 || path.lastIndexOf('/', 0) === 0 || path.lastIndexOf('#', 0) === 0) {
		isLocal = true;
	}

	return isLocal;
}
