import React from 'react';
import { Helmet } from 'react-helmet';
import decodeHtmlEntities from '../../../utilities/decodeHtmlEntities';
import getFeaturedImage from '../../../utilities/getFeaturedImage';

const Head = props => {

	const { data } = props;

	if (data) {

		let type = '';

		if (data.type && data.type !== 'page') {
			type = ` - ${data.type.charAt(0).toUpperCase() + data.type.slice(1)}`;
		}

		const image = data.acf.metaImage
			? data.acf.metaImage.url
			: getFeaturedImage(data._embedded)
				? getFeaturedImage(data._embedded).source_url
				: '/img/og-image.jpg';

		let title = data.acf.metaTitle
			? `${data.acf.metaTitle}${type}`
			: `${data.title.rendered}${type}`;

		let description = data.acf.metaDescription
			? data.acf.metaDescription
			: data.title.rendered;

		title = decodeHtmlEntities(title);
		description = decodeHtmlEntities(description);

		const url = data.link ? data.link : 'https://allylogistics.com';

		return (
			<Helmet>
				<title>{title} - Ally Logistics</title>
				<meta name="description" content={description} />
				<meta name="keywords" content={data.acf.metaKeywords} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="og:image" content={image} />
				<meta property="og:url" content={url} />
				<meta property="og:site_name" content="Ally Logistics" />
				<meta name="twitter:card" content="summary_large_image" />
				<script type="application/ld+json">
					{`
						"@context": "http://schema.org",
						"@type": "Article",
						"image": "${image}",
						"headline": "${title}",
						"about": "${description}",
						"url": "${url}",
						"publisher": {
							"@type": "Organization",
							"name": "AllyLogistics",
							"logo": {
								"@type": "ImageObject",
								"url": "/img/logo.png"
							}
						},
						"mainEntityOfPage": ""
					`}
				</script>
			</Helmet>
		)
	}

	return null;
}

export default Head;
