import React from 'react';
import Button from '../../controls/Button';
import Parallax from '../../elements/Parallax';
import HtmlBlock from '../../utilities/HtmlBlock';

import './index.scss';

const ParallaxList = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';

	return (
		<ul id={props.id} className={`parallax-list${margin}`}>
			{props.listItems.map((item, i) => {
				return (
					<li key={i}>
						<div className="content">
							<HtmlBlock content={item.content} />
							{item.ctaUrl &&
								<Button url={item.ctaUrl}>
									{item.ctaLabel}
								</Button>
							}
						</div>
						<Parallax speed={.1}>
							<img src={item.image.url} alt={item.alt} />
						</Parallax>
					</li>
				);
			})}
		</ul>
	)
}

export default ParallaxList;
