import React from 'react';
import Icon from '../../graphics/Icon';

import './index.scss';

const BackgroundIcon = () => {
	return (
		<div className="bg-icon">
			<Icon />
		</div>
	);
}

export default BackgroundIcon;
