import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Chevron from '../../graphics/Chevron';

import './index.scss';

const mapState = state => ({
	pagesList: state.api.lists.pages
})

class Breadcrumbs extends Component {

	constructor(props) {
		super(props);

		this.state = {
			crumbs: this.buildCrumbs()
		}
	}

	buildCrumbs = () => {
		const crumbs = this.props.location.pathname.replace(/^\/|\/$/g, '').split('/');
		const crumbArray = crumbs.map(slug => this.props.pagesList.find(page => page.slug === slug)).filter(crumb => crumb !== undefined);
		if (crumbArray.length > 0) {
			return crumbArray;
		} else {
			return [];
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({
				crumbs: this.buildCrumbs()
			})
		}
	}

	render() {

		const style = { color: this.props.whiteText ? 'white' : '' }

		if (this.state.crumbs.length > 0) {
			return (
				<ul className={`breadcrumbs${this.state.crumbs.length > 1 ? ' has-multiple' : ''}`}>
					{this.state.crumbs.map((crumb, i) =>
						<li key={i} style={{ ...style }}>
							{i + 1 === this.state.crumbs.length &&
								<span>{crumb.title}</span>
							}
							{i + 1 < this.state.crumbs.length &&
								<Link to={`/${crumb.path}`}>{crumb.title}</Link>
							}
							{i + 1 < this.state.crumbs.length &&
								<Chevron />
							}
						</li>
					)}
				</ul>
			)
		}

		return null;
	}
}

export default withRouter(connect(mapState)(Breadcrumbs));
