import React, { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';

import './index.scss';

const mapDispatch = dispatch => ({
	setWindowHeight: (size) => dispatch({ type: 'SET_WINDOW_HEIGHT', payload: size }),
	setWidths: sizes => dispatch({ type: 'SET_WINDOW_CONTENT_WIDTHS', payload: sizes }),
})

class WindowSize extends Component {

	constructor() {
		super();

		this.contentRef = React.createRef();
		this.frameRef = React.createRef();

		this.state = {
			isMounted: false
		}
	}

	setWindowHeight = () =>
		this.props.setWindowHeight(window.innerHeight)

	setWidths = () => {
		this.props.setWidths({
			window: document.body.clientWidth,
			content: this.contentRef.current && this.contentRef.current.offsetWidth,
			frame: this.frameRef.current && this.frameRef.current.offsetWidth
		})
	}

	onResize = () => {

		// Only resize the height on screens larger than mobile
		// to avoid toolbars hiding and orientation change
		if (document.body.clientWidth > 450) {
			this.setWindowHeight();
		}

		// But resize the width on all widow resize events
		this.setWidths();
	}

	componentDidMount() {
		window.addEventListener('resize', throttle(this.onResize, 400));

		window.addEventListener('orientationchange', () => {
			setTimeout(() => {
				this.onResize();
			}, 500);
		})

		this.setWindowHeight();
		this.setWidths();

		this.setState({
			isMounted: true
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.isMounted !== this.state.isMounted) {
			this.setWindowHeight();
			this.setWidths();
		}
	}

	render() {
		if (this.state.isMounted) {
			return (
				<div className="measurements" ref={this.frameRef}>
					<div className="content-block">
						<div ref={this.contentRef}></div>
					</div>
				</div>
			);
		}
		return null;
	}
}

export default connect(null, mapDispatch)(WindowSize);
