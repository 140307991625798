import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Logo from '../../graphics/Logo';
import Button from '../../controls/Button';

import './index.scss';

const mapStateToProps = state => ({
	header: state.api.header
})

const Header = props => {

	if (props.header) {
		return (
			<header className="header-main">
				<div className="header-wrapper">
					<Link className="logo-home" to="/">
						<Logo />
					</Link>
					<div className="header-right">
						<Button url={`tel:${props.header.phone}`} type='text'>
							{props.header.phone}
						</Button>
						<Button url={props.header.ctaUrl} type='text' arrow={true}>
							{props.header.ctaLabel}
						</Button>
					</div>
				</div>
			</header>
		)
	}
	return null;
}

export default connect(mapStateToProps)(Header);
