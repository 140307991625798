import { Component } from 'react';
import { connect } from 'react-redux';

import api from '../../../api';

import sanitizePostType from '../../../utilities/sanitizePostType';

const mapStateToProps = state => ({
	data: state.api.data
});

const mapDispatchToProps = dispatch => ({
	load: data => dispatch({ type: 'LOAD_DATA', payload: data }),
	loadBySlug: data => dispatch({ type: 'LOAD_DATA_BY_SLUG', payload: data })
});

class LoadContent extends Component {
	constructor(props) {
		super(props);
		this.state = { hasLoaded: false }
		this.load();
	}

	dispatchData = data => {
		const slugs = [];

		data.map((item) => {

			slugs.push({
				type: item.type,
				slug: item.slug
			});

			return this.props.loadBySlug({
				type: sanitizePostType(item.type),
				slug: item.slug,
				data: [item]
			})
		});

		this.props.onLoad && this.props.onLoad(slugs);

		this.setState({ hasLoaded: true });
	}

	handleError = error => {
		console.warn(error);
	}

	load = () => {
		if (this.props.slug) {
			if (!this.props.data[this.props.type][this.props.slug]) {
				// Load page content from API by slug
				api.Content.dataBySlug(this.props.type, this.props.slug).then(
					res => {
						this.props.loadBySlug({
							type: this.props.type,
							slug: this.props.slug,
							data: res
						})
						this.props.onLoad && this.props.onLoad(res);
					},
					error => this.handleError(error)
				);
			}
		} else if (this.props.ids && this.props.ids.length > 0 && !this.state.hasLoaded) {
			api.Content.dataByIds(this.props.ids, sanitizePostType(this.props.type)).then(
				res => this.dispatchData(res),
				error => this.handleError(error)
			);
		} else if (this.props.type && !this.state.hasLoaded) {
			api.Content.data(this.props.type).then(
				res => this.dispatchData(res),
				error => this.handleError(error)
			)
		} else if (this.props.relatedByMetaKey && !this.state.hasLoaded) {
			const query = `meta_key=${this.props.relatedByMetaKey}&meta_value=${this.props.relatedByMetaValue}&compare=LIKE`;
			api.Content.dataByAllTypes(query).then(
				res => this.dispatchData(res),
				error => this.handleError(error)
			)
		} else if (this.props.newestByType && !this.state.hasLoaded) {

			let args = this.props.args ? `${this.props.args}&` : '';
			args += this.props.count ? `per_page=${this.props.count}` : '';
			args += this.props.page ? `page=${this.props.page}` : '';

			api.Content.data(this.props.newestByType, args).then(
				res => this.dispatchData(res),
				error => this.handleError(error)
			)
		} else if (this.props.relatedTo && this.props.taxonomy && this.props.terms) {
			api.Content.dataByRelated(
				this.props.relatedTo,
				this.props.taxonomy,
				this.props.terms,
				this.props.count,
				this.props.types
			).then(
				res => this.dispatchData(res),
				error => this.handleError(error)
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if ((prevProps.ids && prevProps.ids.toString()) !== (this.props.ids && this.props.ids.toString())) {

			this.setState({
				hasLoaded: false
			}, () => {
				this.load();
			})
		}
	}

	render() {
		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadContent);
