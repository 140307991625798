import React, { Component } from 'react';
import ModalContext from '../ModalProvider/context';

import './index.scss';

class ModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    const { isMounted } = this.state;
    const { isAnyOpen } = this.context;
    const baseName = 'modal-container';

    return (
      <div id={baseName} className={`${baseName}${isMounted && isAnyOpen() ? ' open' : ''}`} />
    );
  }
}

ModalContainer.contextType = ModalContext;

export default ModalContainer;
