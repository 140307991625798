import React from 'react';
import PropTypes from 'prop-types';

const Native = (props) => {
  const {
    videoSrc,
    poster,
    htmlVideoAttributes,
  } = props;

  return (
    <video poster={poster} {...htmlVideoAttributes}>
      {videoSrc && <source src={videoSrc} type="video/mp4" />}
    </video>
  );
};

Native.defaultProps = {
  videoSrc: '',
  poster: '',
  htmlVideoAttributes: {
    autoPlay: false,
    muted: false,
    loop: false,
    controls: true,
  },
};

Native.propTypes = {
  videoSrc: PropTypes.string,
  poster: PropTypes.string,
  htmlVideoAttributes: PropTypes.shape({
    autoPlay: PropTypes.bool,
    muted: PropTypes.bool,
    loop: PropTypes.bool,
    controls: PropTypes.bool,
  }),
};

export default Native;
