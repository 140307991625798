import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import queryString from 'qs';

import LoadContent from '../../utilities/LoadContent';
import smoothScroll from '../../../utilities/smoothScroll';

import Default from '../Default';
import NotFound from '../NotFound';
import Footer from '../../layout/Footer';
import Blog from '../Blog';
import Post from '../Post';

import api from '../../../api';
import canUseDom from '../../../utilities/canUseDom';

const templates = {
  default: Default,
  'not-found': NotFound,
  blog: Blog,
  post: Post
}

const mapStateToProps = state => ({
  data: state.api.data
})

class LoadTemplate extends Component {

  constructor(props) {
    super(props);

    this.scrollTimeout = null;

    this.state = {
      preview: false,
      hasNoData: false,
      template: this.props.template ? this.props.template : 'default',

      // Slug will either come from a prop or a URL param from Router
      // Necessary because some slugs come from URL params
      slug: this.props.slug
        ? this.props.slug
        : this.props.match.params.slug
    }

    this.data = {};
  }

  checkForPreview() {
    if (canUseDom) {
      let params = [];

      params = queryString.parse(
        window.location.search,
        { ignoreQueryPrefix: true }
      );

      if (params.preview === 'true' && params._wpnonce && this.state.preview === false) {
        if (params.id) {
          api.Content.previewDataById(this.props.type, params.id, params._wpnonce).then(
            res => {
              this.setState({ preview: res });
            },
            error => {
              console.warn(error);
              this.props.history.push('/not-found');
            }
          )
        } else {
          api.Content.previewDataBySlug(this.props.type, this.state.slug, params._wpnonce).then(
            res => {
              this.setState({ preview: res })
            },
            error => {
              console.warn(error);
              this.props.history.push('/not-found');
            }
          )
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.sendToGA();
      this.setState({
        slug: this.props.match.params.slug
      })
    }

    if (this.props.location.hash.substring(1)) {
      this.scrollTimeout = setTimeout(() => {
        smoothScroll.scrollTo(this.props.location.hash.substring(1));
      }, 500);
    }
  }

  handleLoaded = res => {
    if (!res.length && this.state.slug !== 'wp-draft') {
      this.setState({ hasNoData: true })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.preview !== nextState.preview
      || this.state.hasNoData !== nextState.hasNoData
      || ((this.props.data[this.props.type] && nextProps.data[nextProps.type]) && (this.props.data[this.props.type][this.state.slug] !== nextProps.data[nextProps.type][nextState.slug]))
      || this.state.slug !== nextState.slug) {

      // If any of the above conditions are true,
      // Rerendering is allowed
      return true;
    }

    return false;
  }

  componentDidMount() {
    this.sendToGA();
  }

  sendToGA = () => {
    if (canUseDom && typeof window.ga === 'function') {
      window.ga('create', 'UA-39561826-1', 'auto');
      window.ga('set', 'page', window.location.pathname);
      window.ga('send', 'pageview');
    }
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTimeout);
  }

  render() {

    this.checkForPreview();

    this.data = this.state.preview;

    const url = this.props.location && this.props.location.pathname ? `https://allylogistics.com${this.props.location.pathname}` : '';

    if (!this.state.preview && this.props.data[this.props.type] && this.props.data[this.props.type][this.state.slug]) {
      this.data = this.props.data[this.props.type][this.state.slug];
    }

    const Template = templates[this.state.template];

    if (!Template || this.state.hasNoData || this.state.template === 'not-found') {
      return (
        <div className="template-wrap">
          <NotFound />
        </div>
      )
    }

    return (
      <div className="template-wrap">
        {this.data &&
          <React.Fragment>
            <Helmet>
              <title>{this.data.acf.metaTitle}</title>
              <meta name="description" content={this.data.acf.metaDescription} />
              <meta name="keywords" content={this.data.acf.metaKeywords} />
              <meta property="og:url" content={url} />
              {this.data.acf.metaOgImage ? (
                <meta property="og:image" content={this.data.acf.metaOgImage.url} />
              ) : (
                <meta property="og:image" content='https://allylogistics.com/images/ally-og-image.jpg' />
              )}
              {this.data.acf.hidePage && (
                <meta name="robots" content='noindex, nofollow' />
              )}
            </Helmet>
            <Template data={this.data} slug={this.state.slug} location={this.props.location} history={this.props.history} />
            <Footer />
          </React.Fragment>
        }
        <LoadContent type={this.props.type} slug={this.state.slug} onLoad={this.handleLoaded} />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(LoadTemplate));
