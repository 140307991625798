import React from 'react';
import PropTypes from 'prop-types';
import asModal from '../../modal/asModal';
import ModalToggler from '../../modal/ModalToggler';
import SiteWidth from '../../layout/SiteWidth';
import Video from '../Video';
import Hamburger from '../../graphics/Hamburger';

import './index.scss';

const baseName = 'video-modal';

const VideoModal = (props) => {
  const {
    className,
    slug,
    isOpen,
    videoProps,
  } = props;

  const classes = [
    className,
    baseName,
    isOpen && 'is-open',
  ].filter(Boolean).join(' ');

  if (slug && isOpen) {
    return (
      <div className={classes}>
        <div className={`${baseName}_wrapper`}>
          <SiteWidth>
            <div className={`${baseName}_content-flex`}>
              <ModalToggler slug={slug}>
                <Hamburger className="active" />
              </ModalToggler>

              <Video {...videoProps} />
            </div>
          </SiteWidth>
        </div>
      </div>
    );
  }

  return null;
};

VideoModal.defaultProps = {
  className: '',
  slug: '',
  videoProps: {},
};

VideoModal.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  videoProps: PropTypes.shape({}),
};

export default asModal(VideoModal);
