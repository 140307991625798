export default (elem) => {

	// Set our distance placeholder
	var distance = 0;

	// Loop up the DOM
	if (elem.offsetParent) {
		do {
			distance += elem.offsetTop;
			elem = elem.offsetParent;
		} while (elem);
	}

	// Return our distance
	return distance < 0 ? 0 : distance;
}
