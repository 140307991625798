import React, { Component } from 'react';
import Head from '../../elements/Head';
import canUseDom from '../../../utilities/canUseDom';
import api from '../../../api';
import queryString from 'qs';
import Footer from '../../layout/Footer';
import templates from '../';

import '../index.scss';

class LoadPreview extends Component {
	constructor() {
		super();

		this.state = {
			data: null,
			meta: null,
			params: canUseDom && queryString.parse(
				window.location.search,
				{ ignoreQueryPrefix: true }
			)
		}
	}

	componentDidMount() {
		this.checkForPreview();
	}

	checkForPreview() {

		const { params } = this.state;
		const { type } = this.props.match.params;

		api.Content.previewDataById(type, params.id, params._wpnonce).then(
			res => this.setState({ data: res }),
			error => {
				console.warn(error);
				this.props.history.push('/not-found');
			}
		);
	}

	render() {
		const { data } = this.state;

		if (data) {

			const template = data.template_slug;
			const TemplateComponent = templates[template];

			return (
				<div className="template-wrap">
					<Head data={data} />
					<TemplateComponent data={data} location={this.props.location} match={this.props.match} />
					<Footer />
				</div>
			)
		}

		return null;
	}
}

export default LoadPreview;