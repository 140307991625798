import React, { Component } from 'react';
import HtmlBlock from '../../utilities/HtmlBlock';
import decodeHtmlEntities from '../../../utilities/decodeHtmlEntities';

import './index.scss';

class Form extends Component {

	constructor() {
		super();

		this.state = {
			init: false,
			formAdded: false,
			decodedForm: ''
		}
	}

	componentDidMount() {
		this.setState({
			init: true,
			decodedForm: decodeHtmlEntities(decodeHtmlEntities(this.props.formCode))
		})
	}

	initializeForm = () => {
		
		const scriptOpeningTag = /<script[^>]+>/.exec(this.state.decodedForm);
		const scriptOpeningTagEnd = scriptOpeningTag.index + scriptOpeningTag[0].length;
		const scriptClosingTagStart = this.state.decodedForm.indexOf('</script>');

		const trimmedScript = this.state.decodedForm.substring(scriptOpeningTagEnd, scriptClosingTagStart);

		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.innerHTML = trimmedScript;

		document.getElementById(this.props.id).appendChild(script);

		this.setState({
			formAdded: true
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.init !== this.state.init && this.state.init) {
			this.initializeForm();
		}
	}

	render() {

		if (this.state.init && this.state.decodedForm !== '') {
			const margin = this.props.margin && this.props.margin !== 'none' ? ` margin-${this.props.margin}` : '';
			const optionalTopMargin = this.props.shouldUseTopMargin ? ' top-form-margin' : '';
			const formElement = this.state.decodedForm.substring(0, this.state.decodedForm.indexOf('<script'));

			return (
				<div id={this.props.id} className={`wufoo-form${margin}${optionalTopMargin}`}>
					<HtmlBlock className="content" content={formElement} />
				</div>
			)
		}

		return null;
	}
}

export default Form;
