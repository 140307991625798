import React from 'react';

import getTermsByTaxonomy from '../../../utilities/getTermsByTaxonomy';

import './index.scss';

const CategoriesList = props => {

	const data = props.data;

	if (data) {

		const categories = getTermsByTaxonomy(data, 'category');
		const filteredCategories = categories.filter(cat => cat.slug !== 'uncategorized');

		if (filteredCategories.length > 0) {
			return (
				<div className="categories-list">
					{filteredCategories.map((category, index) => {
						if (category.slug !== 'uncategorized') {
							return <span key={index}>{category.name}</span>
						} return null;
					})}
				</div>
			)
		}

		return null;
	}

	return null;
}

export default CategoriesList;