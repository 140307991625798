import canUseDom from './canUseDom';

export default permalink => {

	let formattedPermalink = permalink;

	if (canUseDom) {
		if (permalink.lastIndexOf(window.location.origin, 0) === 0) {
			formattedPermalink = permalink.replace(window.location.origin, '');
		}
	}

	if (permalink.lastIndexOf(process.env.REACT_APP_URL, 0) === 0) {
		formattedPermalink = permalink.replace(process.env.REACT_APP_URL, '');
	}

	if (permalink.lastIndexOf(process.env.REACT_APP_API_URL, 0) === 0) {
		formattedPermalink = permalink.replace(process.env.REACT_APP_API_URL, '');
	}

	// remove trailing slash that WordPress ACF "Page Link" sends back in URL string
	return formattedPermalink.replace(/\/$/, "");

}
