import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import HtmlBlock from '../../utilities/HtmlBlock';
import Button from '../../controls/Button';
import PlayButton from '../../graphics/PlayButton';
import ModalToggler from '../../modal/ModalToggler';
import VideoModal from '../../elements/VideoModal';
import { videoPropFormatter } from '../../elements/Video/helpers';
import Breadcrumbs from '../../elements/Breadcrumbs';
import Parallax from '../../elements/Parallax';

import './index.scss';
import ImageTiles from './ImageTiles';

const ContentWithVideo = (props) => {
  const {
    content,
    embeddedVideo,
    showBreadcrumbs,
    contentWidth,
    optionalButton: {
      show,
      pageUrl,
      customUrl,
      linkType,
      type,
      arrow,
      label,
    },
    optionalSecondaryButton: {
      show: secondaryShow,
      pageUrl: secondaryPageUrl,
      customUrl: secondaryCustomUrl,
      linkType: secondaryLinkType,
      type: secondaryButtonType,
      arrow: secondaryArrow,
      label: secondaryLabel,
    },
    padding,
    margin,
    firstTileImage: {
      url: firstTileUrl,
    },
    secondTileImage: {
      url: secondTileUrl,
    },
    thirdTileImage: {
      url: thirdTileUrl,
    },
    id,
  } = props;

  const baseName = 'content-with-video';
  const classes = [
    baseName,
    margin && `margin-${margin}`,
  ].filter(Boolean).join(' ');

  const hasButton = show || secondaryShow;
  const url = linkType === 'page' ? pageUrl : customUrl;
  const secondaryButtonUrl = secondaryLinkType === 'page' ? secondaryPageUrl : secondaryCustomUrl;
  const videoProps = videoPropFormatter(embeddedVideo);

  const tileLayout = [
    firstTileUrl,
    secondTileUrl,
    thirdTileUrl,
  ].filter(Boolean).length;

  return (
    <div className={classes}>
      <div className={`row padding-${padding}`}>
        <div className={`column col${contentWidth} ${hasButton ? ' has-button' : ''}`}>

          {showBreadcrumbs
            && <Breadcrumbs whiteText />
          }

          <HtmlBlock content={content} />

          {show
            && (
              <Button url={url} type={type} arrow={arrow}>
                {label}
              </Button>
            )
          }

          {secondaryShow
            && (
              <Button url={secondaryButtonUrl} type={secondaryButtonType} arrow={secondaryArrow}>
                {secondaryLabel}
              </Button>
            )
          }
        </div>

        <div className={`column col${12 - contentWidth} video-section ${!secondTileUrl && 'align-image-right'}`}>
          <Parallax speed={0.2}>
            <ModalToggler slug={id} className="clickable-tiles" wrapWithDiv>

              <ImageTiles imageUrls={[firstTileUrl, secondTileUrl, thirdTileUrl]} />
              <PlayButton />

            </ModalToggler>
            <VideoModal slug={id} videoProps={videoProps} />
          </Parallax>
        </div>
      </div>
    </div>
  );
};

ContentWithVideo.defaultProps = {
  showBreadcrumbs: false,
  contentWidth: '8',
  optionalButton: {},
  optionalSecondaryButton: {},
  padding: 'none',
  margin: 'none',
  embeddedVideo: {},
};

ContentWithVideo.propTypes = {
  showBreadcrumbs: PropTypes.bool,
  contentWidth: PropTypes.oneOf([
    '6',
    '8',
  ]),
  content: PropTypes.string.isRequired,
  embeddedVideo: PropTypes.shape({}),
  optionalButton: PropTypes.shape({
    show: PropTypes.bool,
    arrow: PropTypes.bool,
    customUrl: PropTypes.string,
    label: PropTypes.string,
    linkType: PropTypes.oneOfType([
      PropTypes.oneOf([
        'page',
        'custom',
      ]),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    pageUrl: PropTypes.string,
    type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }),
  optionalSecondaryButton: PropTypes.shape({
    show: PropTypes.bool,
    arrow: PropTypes.bool,
    customUrl: PropTypes.string,
    label: PropTypes.string,
    linkType: PropTypes.oneOfType([
      PropTypes.oneOf([
        'page',
        'custom',
      ]),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    pageUrl: PropTypes.string,
    type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }),
  firstTileImage: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  secondTileImage: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  thirdTileImage: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  padding: PropTypes.oneOf([
    'none',
    'small',
    'medium',
    'large',
  ]),
  margin: PropTypes.oneOf([
    'none',
    'small',
    'medium',
    'large',
  ]),
  id: PropTypes.string.isRequired,
};

export default ContentWithVideo;
