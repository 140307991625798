import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Routes from './Routes';
import WatchSearchQuery from './components/utilities/WatchSearchQuery';
import MeasureScroll from './components/utilities/MeasureScroll';
import smoothScroll from './utilities/smoothScroll';
import WindowSize from './components/utilities/WindowSize';
import Header from './components/layout/Header';
import Sidebar from './components/layout/Sidebar';
import BackgroundVideo from './components/layout/BackgroundVideo';
import BackgroundIcon from './components/layout/BackgroundIcon';
import api from './api';
import ModalProvider from './components/modal/ModalProvider';
import ModalContainer from './components/modal/ModalContainer';
import BackgroundVideoProvider from './components/providers/BackgroundVideoProvider';

import './scss/app.scss';

const mapStateToProps = state => ({
  pageList: state.api.lists.pages,
  sidebarOpen: state.browser.sidebarOpen,
});

const mapDispatchToProps = dispatch => ({
  loadGlobals: data => dispatch({ type: 'LOAD_GLOBAL_DATA', payload: data }),
});

class App extends Component {
  constructor(props) {
    super(props);
    this.props.loadGlobals(api.Content.globals());
  }

  componentDidUpdate(prevProps) {
    const prevRoute = `${prevProps.location.pathname}${prevProps.location.hash}`;
    const newRoute = `${this.props.location.pathname}${this.props.location.hash}`;

    // On route change, scroll to specific location or top of page
    if (newRoute !== prevRoute) {
      if (this.props.location.hash.substring(1)) {
        smoothScroll.scrollTo(this.props.location.hash.substring(1));
      } else {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <BackgroundVideoProvider>
          <BackgroundVideo />
          <BackgroundIcon />
          <ModalProvider>
            <div className={`site-wrap${this.props.sidebarOpen ? ' sidebar-open' : ''}`}>
              <Sidebar />
              <div className="main-wrap">
                <Header />
                <Routes pages={this.props.pageList} />
                <WindowSize />
              </div>
              <ModalContainer />
            </div>
          </ModalProvider>
        </BackgroundVideoProvider>
        <WatchSearchQuery />
        <MeasureScroll />
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
