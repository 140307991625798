import React from 'react';

const Chevron = () => {
	return (
		<svg className="graphic chevron" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32">
			<polyline className="stroke" points="8 0 24 16 8 32" />
		</svg>
	)
}

export default Chevron;
