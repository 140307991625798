import React from 'react';

import './index.scss';

const EmbeddedImage = React.forwardRef((props, ref) => {

	const classes = props.className ? `embedded-image ${props.className}` : 'embedded-image';

	if (props.image) {

		let url = '';
		let alt = '';
		const size = props.size ? props.size : 'full';

		if (props.image['wp:featuredmedia']) {
			url = props.image['wp:featuredmedia'][0]['media_details'].sizes[size]['source_url'];
			alt = props.image['wp:featuredmedia'][0].alt_text;
		} else if (props.image.media_details) {
			url = props.image.media_details.sizes[size].source_url;
		} else if (props.image.sizes) {
			url = props.size ? props.image.sizes[size] : props.image.url;
			alt = props.image.alt;
		}

		if (props.cover) {
			return (
				<div className={`${classes} cover`} style={{ backgroundImage: `url(${url})` }}>
					<img ref={ref} onLoad={props.onLoad} src={url} alt={alt} />
				</div>
			)
		}

		return <img ref={ref} className={classes} onLoad={props.onLoad} src={url} alt={alt} />
	}

	return <div ref={ref} className={`${classes} no-image`} />;
})

export default EmbeddedImage;
