import React from 'react';

import Button from '../../controls/Button';

import './index.scss';

const CallToAction = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';
	const url = props.button.linkType === 'page' ? props.button.pageUrl : props.buttom.customUrl;

	return (
		<div id={props.id} className={`call-to-action${margin}`}>
			<div className="cta-wrap">
				<Button url={url} type='text lrg' arrow={true}>
					{props.button.label}
				</Button>
			</div>
		</div>
	)
}

export default CallToAction;
