import PropTypes from 'prop-types';

export const defaultProps = {
  className: '',
};

export const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
