
import React from 'react';

const Arrow = props => {
	return (
		<svg className="graphic arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
			<polyline className="stroke" points="16 0 32 16 16 32" />
			<path className="stroke" d="M0,16 L32,16" />
		</svg>
	)
}

export default Arrow;