import React from 'react';

import * as blocks from '../../blocks';

import './index.scss';

export default props => {

	if (props.content) {

		return (
			<div className="flexible-content" id={props.id} style={props.style}>

				{props.content.map((block, i) => {
					const name = block.acf_fc_layout;
					const Block = blocks[name];
					const nextBlockId = props.content[i + 1] ? `${props.content[i + 1].acf_fc_layout}-${i + 1}` : undefined;

					if (Block) {
						return <Block {...block} id={`${name}-${i}`} nextBlockId={nextBlockId} key={i} />;
					}

					return null;
				})}

			</div>
		)
	}

	return null;
}
