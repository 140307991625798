export const videoPropFormatter = (embeddedVideo) => {
  const {
    player,
    videoTitle,
    videoFile: {
      url,
    },
    vimeoId,
    youtubeId,
    autoplay,
    customAspectRatio,
    showControls,
    muted,
    loop,
  } = embeddedVideo;

  const videoSrc = (player === 'vimeo' && vimeoId)
    || (player === 'youtube' && youtubeId)
    || url;

  return (
    {
      videoTitle,
      videoSrc,
      player,
      aspectRatio: customAspectRatio,
      autoplay,
      muted,
      showControls,
      loop,
      htmlVideoAttributes: {
        autoPlay: autoplay,
        muted,
        controls: showControls,
        loop,
      },
    }
  );
};
