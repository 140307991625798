function decodeHtmlEntities(encodedString) {
	var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
	var translate = {
		"nbsp": " ",
		"amp": "&",
		"quot": "\"",
		"lt": "<",
		"gt": ">"
	};
	return encodedString.replace(translate_re, function (match, entity) {
		return translate[entity];
	}).replace(/&#(\d+);/gi, function (match, numStr) {
		var num = parseInt(numStr, 10);
		return String.fromCharCode(num);
	});
}

export default decodeHtmlEntities;
