import React, { Component } from 'react';
import { connect } from 'react-redux';

import HtmlBlock from '../../../utilities/HtmlBlock';
import Breadcrumbs from '../../../elements/Breadcrumbs';
import breakpoints from '../../../../breakpoints';

const mapState = state => ({
	windowWidth: state.browser.windowWidth,
})

class HeaderAndImage extends Component {

	Header() {
		return (
			<div className="heading">
				{this.props.showBreadcrumbs &&
					<Breadcrumbs whiteText={this.props.whiteText} />
				}
				<div className="row">
					<HtmlBlock content={this.props.heading} />
				</div>
			</div>
		)
	}

	Image() {
		const shouldBreak = this.props.windowWidth >= breakpoints.mid ? true : false;
		const imageUrl = this.props.backgroundImage ? this.props.backgroundImage.url : null;

		return (
			shouldBreak
				? <div className="featured-image" style={{ backgroundImage: `url(${imageUrl})` }} /> 
				: <img className="featured-image-standalone" src={imageUrl} alt=""/>
		)
	}



	render() {
		const shouldBreak = this.props.windowWidth >= breakpoints.mid ? true : false;
		if(shouldBreak) {
			return (
				<React.Fragment>
					{this.Image()}
					{this.Header()}
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					{this.Header()}
					{this.Image()}
				</React.Fragment>
			);
		}
	}
}

export default connect(mapState)(HeaderAndImage);