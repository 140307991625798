import React from 'react';

const PlayButton = (props) => {
  return (
    <svg className="graphic play-button" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <circle className="circle" cx="24" cy="24" r="24" />
      <polygon className="triangle" points="19,15.3 19,33.1 35.1,24.2 " />
    </svg>
  );
};

export default PlayButton;
