import React from 'react';

import HtmlBlock from '../../utilities/HtmlBlock';
import Button from '../../controls/Button';

import './index.scss';

const ImageContent = props => {

	let classes = 'image-content';
	classes += props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';
	classes += ` alignment-${props.alignment}`;

	const optionalButton = props.content.optionalButton;
	const url = optionalButton.linkType === 'page' ? optionalButton.pageUrl : optionalButton.customUrl;

	return (
		<div id={props.id} className={classes}>
			<div className="row">
				<div className="content">
					<HtmlBlock content={props.content.wysiwyg} />
					{optionalButton && optionalButton.show &&
						<Button url={url} type={optionalButton.type} arrow={optionalButton.arrow}>{optionalButton.label}</Button>
					}
				</div>
				<div className="image">
					<div className="wrap">
						{props.image &&
							<img className="main" src={props.image.url} alt={props.image.alt} />
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImageContent
