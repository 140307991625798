import React from 'react';

import './index.scss';

const Hamburger = props => {

	const baseClasses = 'graphic hamburger';
	const classes = props.className ? `${baseClasses} ${props.className}` : baseClasses;

	return (
		<svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16">
			<line className="stroke" x2="32" />
			<line className="stroke" y1="8" x2="32" y2="8" />
			<line className="stroke" y1="8" x2="32" y2="8" />
			<line className="stroke" y1="16" x2="32" y2="16" />
		</svg>
	)
}

export default Hamburger;
