import React, { Component } from 'react';
import { connect } from 'react-redux';
import Hyperlink from '../../controls/Hyperlink';
import OptionalButton from '../../elements/OptionalButton';
import HtmlBlock from '../../utilities/HtmlBlock';
import stylizeNumber from '../../../utilities/stylizeNumber';
import Arrow from '../../graphics/Arrow';
import breakpoints from '../../../breakpoints';

import './index.scss';

const mapState = state => ({
	servicesGrid: state.api.servicesGrid,
	windowWidth: state.browser.windowWidth,
})

class NavigationGrid extends Component {
	constructor() {
		super();

		this.state = {
			active: null
		}
	}

	render() {
		
		const margin = this.props.margin && this.props.margin !== 'none' ? ` margin-${this.props.margin}` : '';
		const sidebarStatus = this.props.optionalContent ? ' with-sidebar' : '';

		const gridContent = this.props.gridPopulation === 'services'
			? this.props.servicesGrid.navigationGrid
			: this.props.manualGrid.navigationGrid;

		return (
			<div className={`navigation-grid${margin}${sidebarStatus}`} id={this.props.id}>
				<div className="row">
					{this.props.optionalContent &&
						<div className="navigation-grid-sidebar">
							<HtmlBlock content={this.props.optionalContent} />
							<OptionalButton {...this.props.optionalButton} />
						</div>
					}
					{gridContent &&
						<div className="grid">
							<ul className="bg-images">
								{this.props.windowWidth > breakpoints.mobileBreak && gridContent.map((item, i) => {
									return (
										<li key={i} className={this.state.active === i ? 'open' : undefined} style={{ backgroundImage: `url(${item.backgroundImage.url})` }} />
									)
								})}
							</ul>
							<ul className="tiles" onMouseLeave={() => this.setState({ active: null })}>
								{gridContent.map((item, i) => {
									let tileRedirectUrl;
									if (this.props.quoteButtons.useTileQuoteRedirect) {
										tileRedirectUrl = item.quoteLink.linkType === 'page' ? item.quoteLink.pageUrl : item.quoteLink.customUrl;
									} else {
										tileRedirectUrl = item.button.linkType === 'page' ? item.button.pageUrl : item.button.customUrl;
									}

									let listItemStyle = {};
									if (this.props.windowWidth <= breakpoints.mobileBreak) {
										listItemStyle.backgroundImage = `linear-gradient(to bottom, rgba(34, 34, 34, 0.4), rgba(34, 34, 34, 0.4)), url(${item.backgroundImage.url})`;
										listItemStyle.backgroundSize = 'cover';
									}

									return (
										<li key={i} className={`grid-item${this.state.active === i ? ' active' : ''}`} style={listItemStyle} onMouseEnter={() => this.setState({ active: i })}>
											<div className="square">
												<Hyperlink url={tileRedirectUrl}>
													<header className="title">
														<h5>{item.button.label}</h5>
														<span className="index">{stylizeNumber(i + 1)}</span>
													</header>
													<aside>
														<HtmlBlock content={item.hoveredContent} />
													</aside>
												</Hyperlink>
												{this.props.quoteButtons.showQuoteButtons &&
													<Hyperlink className="inline-hyperlink btn" url={item.quoteLink.linkType === 'page' ? item.quoteLink.pageUrl : item.quoteLink.customUrl}>
														<span>Request a quote</span> <Arrow />
													</Hyperlink>
												}
											</div>
										</li>
									)
								})}
							</ul>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default connect(mapState)(NavigationGrid);
