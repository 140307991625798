import React from 'react';
import Button from '../../controls/Button';

const OptionalButton = props => {
	if (props.show) {
		return (
			<Button {...props} url={props.linkType === 'page' ? props.pageUrl : props.customUrl}>
				{props.label}
			</Button>
		)
	}

	return null;
}

export default OptionalButton;
