import React from 'react';

import './index.scss';

const NotFound = props => {
	return (
		<React.Fragment>
			<article className="not-found">
				<h1>Not Found</h1>
			</article>
		</React.Fragment>
	)
}

export default NotFound;