import * as React from 'react';

const BackgroundVideoContext = React.createContext({
  pageBackgroundInfo: null,
  setPageBackgroundInfo: undefined,
});

const BackgroundVideoProvider = ({ children }) => {
  const [pageBackgroundInfo, setPageBackgroundInfo] = React.useState(null);

  return (
    <BackgroundVideoContext.Provider value={{
      pageBackgroundInfo,
      setPageBackgroundInfo,
    }}
    >
      {children}
    </BackgroundVideoContext.Provider>
  );
};

export default BackgroundVideoProvider;
export const useBackgroundVideo = () => React.useContext(BackgroundVideoContext);
