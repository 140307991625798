import React from 'react';

const Logo = props => {

	const colors = {
		primary: '#3594ff',
		secondary: '#FFFFFF'
	}

	return (
		<svg className="logo graphic" version="1.1" viewBox="0 0 192 22" width="192" height="22" xmlns="http://www.w3.org/2000/svg">
			<path fill={colors.primary} d="M12.827991,8.380951h7.28863l-7.28863,8.380955h4.956268L21.83032,5.615499
			c0.16152-0.44471-0.207291-0.901209-0.728281-0.901209h-8.274048L15.160351,0H27.41749
			c1.461519,0,2.522449,1.24902,2.13645,2.515329L23.61516,22H0L12.827991,8.380951z"/>
			<path fill={colors.secondary} d="M52.192932,20h-3.334961l-0.966309-2.898438h-5.818848L41.084045,20h-3.334961l5.956543-16.375977h2.507324L52.192932,20z
				 M47.041077,14.411133l-2.001465-5.957031l-2.069824,5.957031H47.041077z M64.014709,20H53.388733V3.624023h3.196777v13.524414
				h7.429199V20z M76.411682,20H65.785706V3.624023h3.196777v13.524414h7.429199V20z M89.360413,3.624023l-4.921875,9.660156V20
				h-3.173828v-6.71582l-4.922363-9.660156h3.473145l3.059082,6.600586l3.012695-6.600586H89.360413z M101.052795,20H90.679749
				V3.624023h2.483887v14.144531h7.88916V20z M114.219788,10.029297c0.007324,0.314453,0.01123,0.90918,0.01123,1.782227
				c0,0.874023-0.003906,1.46875-0.01123,1.783203c-0.008301,0.314453-0.034668,0.774414-0.080566,1.379883
				s-0.123047,1.057617-0.22998,1.356445c-0.107422,0.299805-0.26123,0.636719-0.459961,1.012695
				c-0.199707,0.375977-0.459961,0.724609-0.782227,1.045898c-1.165527,1.166016-2.606934,1.748047-4.32373,1.748047
				c-1.702148,0-3.13623-0.582031-4.30127-1.748047c-0.321777-0.321289-0.583008-0.669922-0.781738-1.045898
				c-0.199707-0.375977-0.356934-0.716797-0.47168-1.023438c-0.115234-0.306641-0.195312-0.750977-0.241699-1.333984
				c-0.045898-0.583008-0.072754-1.050781-0.080078-1.40332c-0.008301-0.352539-0.011719-0.943359-0.011719-1.771484
				c0-0.827148,0.003418-1.417969,0.011719-1.770508c0.007324-0.352539,0.03418-0.820312,0.080078-1.40332
				c0.046387-0.582031,0.126465-1.026367,0.241699-1.333984c0.114746-0.306641,0.271973-0.647461,0.47168-1.023438
				c0.19873-0.375,0.459961-0.724609,0.781738-1.045898c1.165039-1.165039,2.599121-1.748047,4.30127-1.748047
				c1.716797,0,3.158203,0.583008,4.32373,1.748047c0.322266,0.321289,0.58252,0.670898,0.782227,1.045898
				c0.19873,0.375977,0.352539,0.712891,0.459961,1.012695c0.106934,0.298828,0.184082,0.750977,0.22998,1.356445
				C114.18512,9.255859,114.211487,9.71582,114.219788,10.029297z M111.563049,15.273438
				c0.122559-0.651367,0.184082-1.805664,0.184082-3.461914c0-1.655273-0.061523-2.80957-0.184082-3.460938
				c-0.123047-0.651367-0.383301-1.192383-0.781738-1.62207c-0.628906-0.673828-1.441406-1.011719-2.437988-1.011719
				c-0.99707,0-1.80957,0.337891-2.437988,1.011719c-0.398926,0.429688-0.659668,0.970703-0.782227,1.62207
				c-0.123047,0.651367-0.184082,1.805664-0.184082,3.460938c0,1.65625,0.061035,2.810547,0.184082,3.461914
				c0.122559,0.652344,0.383301,1.192383,0.782227,1.621094c0.628418,0.675781,1.440918,1.012695,2.437988,1.012695
				c0.996582,0,1.809082-0.336914,2.437988-1.012695C111.179749,16.46582,111.440002,15.925781,111.563049,15.273438z
				 M128.353577,13.652344c0,2.039062-0.491211,3.580078-1.472168,4.623047c-1.181152,1.241211-2.683594,1.862305-4.507812,1.862305
				c-1.702148,0-3.13623-0.582031-4.30127-1.748047c-0.321777-0.321289-0.583008-0.669922-0.781738-1.045898
				c-0.199707-0.375977-0.356934-0.716797-0.47168-1.023438c-0.115234-0.306641-0.195312-0.750977-0.241699-1.333984
				c-0.045898-0.583008-0.072754-1.050781-0.080078-1.40332c-0.008301-0.352539-0.011719-0.943359-0.011719-1.771484
				c0-0.827148,0.003418-1.417969,0.011719-1.770508c0.007324-0.352539,0.03418-0.820312,0.080078-1.40332
				c0.046387-0.582031,0.126465-1.026367,0.241699-1.333984c0.114746-0.306641,0.271973-0.647461,0.47168-1.023438
				c0.19873-0.375,0.459961-0.724609,0.781738-1.045898c1.165039-1.165039,2.599121-1.748047,4.30127-1.748047
				c1.655762,0,3.001465,0.475586,4.036133,1.425781c1.035156,0.951172,1.675293,2.169922,1.920898,3.657227h-2.507324
				c-0.214844-0.905273-0.617188-1.606445-1.20752-2.104492s-1.337891-0.748047-2.242188-0.748047
				c-0.981445,0-1.786621,0.337891-2.415039,1.011719c-0.398926,0.445312-0.663574,0.993164-0.793457,1.644531
				c-0.130859,0.652344-0.195801,1.798828-0.195801,3.438477c0,1.641602,0.064941,2.791016,0.195801,3.450195
				c0.129883,0.660156,0.394531,1.211914,0.793457,1.65625c0.612793,0.65918,1.417969,0.989258,2.415039,0.989258
				c1.104004,0,1.992676-0.383789,2.667969-1.150391c0.551758-0.643555,0.827637-1.501953,0.827637-2.576172v-0.919922h-3.495605
				v-2.116211h5.97998V13.652344z M133.666077,20h-2.483887V3.624023h2.483887V20z M147.350647,15.331055
				c0,1.487305-0.544434,2.660156-1.632812,3.518555c-1.089355,0.859375-2.507324,1.288086-4.255371,1.288086
				c-1.257324,0-2.345703-0.164062-3.265625-0.494141c-0.92041-0.330078-1.763672-0.885742-2.530273-1.667969l1.65625-1.632812
				c1.04248,1.042969,2.437988,1.564453,4.186035,1.564453c1.072754,0,1.904785-0.21875,2.495117-0.655273
				c0.590332-0.4375,0.885742-1.054688,0.885742-1.851562c0-0.751953-0.214844-1.319336-0.644043-1.702148
				c-0.368164-0.336914-0.96582-0.567383-1.793945-0.69043l-1.932129-0.275391c-1.333984-0.18457-2.369141-0.621094-3.10498-1.311523
				c-0.797363-0.750977-1.195801-1.793945-1.195801-3.12793c0-1.441406,0.490234-2.602539,1.47168-3.484375
				s2.31543-1.322266,4.001953-1.322266c2.054688,0,3.749023,0.621094,5.083008,1.862305l-1.586914,1.564453
				c-0.88916-0.842773-2.077637-1.264648-3.564941-1.264648c-0.935547,0-1.66748,0.237305-2.196289,0.712891
				c-0.529297,0.475586-0.793457,1.088867-0.793457,1.839844c0,0.643555,0.206543,1.149414,0.620605,1.517578
				c0.429199,0.383789,1.04248,0.636719,1.840332,0.758789l1.862793,0.276367c1.364746,0.214844,2.384277,0.621094,3.059082,1.21875
				C146.905823,12.754883,147.350647,13.874023,147.350647,15.331055z M159.908752,5.855469h-4.507812V20h-2.483887V5.855469h-4.508301
				V3.624023h11.5V5.855469z M164.393616,20h-2.483887V3.624023h2.483887V20z M178.975647,15.100586
				c-0.275879,1.595703-0.939453,2.833008-1.989258,3.714844c-1.050781,0.881836-2.334961,1.322266-3.852539,1.322266
				c-1.702148,0-3.13623-0.582031-4.30127-1.748047c-0.321777-0.321289-0.583008-0.669922-0.781738-1.045898
				c-0.199707-0.375977-0.356934-0.716797-0.47168-1.023438c-0.115234-0.306641-0.195312-0.750977-0.241699-1.333984
				c-0.045898-0.583008-0.072754-1.050781-0.080078-1.40332c-0.008301-0.352539-0.011719-0.943359-0.011719-1.771484
				c0-0.827148,0.003418-1.417969,0.011719-1.770508c0.007324-0.352539,0.03418-0.820312,0.080078-1.40332
				c0.046387-0.582031,0.126465-1.026367,0.241699-1.333984c0.114746-0.306641,0.271973-0.647461,0.47168-1.023438
				c0.19873-0.375,0.459961-0.724609,0.781738-1.045898c1.165039-1.165039,2.599121-1.748047,4.30127-1.748047
				c1.532715,0,2.820801,0.436523,3.86377,1.310547c1.04248,0.874023,1.702148,2.116211,1.978027,3.726562h-2.529785
				c-0.199707-0.859375-0.583008-1.541016-1.150391-2.047852c-0.567383-0.505859-1.287598-0.758789-2.161621-0.758789
				c-0.99707,0-1.802246,0.330078-2.415039,0.989258c-0.398926,0.445312-0.663574,0.99707-0.793457,1.65625
				c-0.130859,0.65918-0.195801,1.80957-0.195801,3.449219c0,1.641602,0.064941,2.791016,0.195801,3.450195
				c0.129883,0.660156,0.394531,1.211914,0.793457,1.65625c0.612793,0.65918,1.417969,0.989258,2.415039,0.989258
				c0.874023,0,1.598145-0.25293,2.17334-0.758789c0.575195-0.506836,0.961914-1.188477,1.161621-2.047852H178.975647z
				 M191.694397,15.331055c0,1.487305-0.544434,2.660156-1.632812,3.518555c-1.089355,0.859375-2.507324,1.288086-4.255371,1.288086
				c-1.257324,0-2.345703-0.164062-3.265625-0.494141c-0.92041-0.330078-1.763672-0.885742-2.530273-1.667969l1.65625-1.632812
				c1.04248,1.042969,2.437988,1.564453,4.186035,1.564453c1.072754,0,1.904785-0.21875,2.495117-0.655273
				c0.590332-0.4375,0.885742-1.054688,0.885742-1.851562c0-0.751953-0.214844-1.319336-0.644043-1.702148
				c-0.368164-0.336914-0.96582-0.567383-1.793945-0.69043l-1.932129-0.275391c-1.333984-0.18457-2.369141-0.621094-3.10498-1.311523
				c-0.797363-0.750977-1.195801-1.793945-1.195801-3.12793c0-1.441406,0.490234-2.602539,1.47168-3.484375
				s2.31543-1.322266,4.001953-1.322266c2.054688,0,3.749023,0.621094,5.083008,1.862305l-1.586914,1.564453
				c-0.88916-0.842773-2.077637-1.264648-3.564941-1.264648c-0.935547,0-1.66748,0.237305-2.196289,0.712891
				c-0.529297,0.475586-0.793457,1.088867-0.793457,1.839844c0,0.643555,0.206543,1.149414,0.620605,1.517578
				c0.429199,0.383789,1.04248,0.636719,1.840332,0.758789l1.862793,0.276367c1.364746,0.214844,2.384277,0.621094,3.059082,1.21875
				C191.249573,12.754883,191.694397,13.874023,191.694397,15.331055z"/>
		</svg>

	)
}

export default Logo;
