import React, { Component } from 'react';

import './index.scss';

class GenericScriptTag extends Component {

	componentDidMount() {

		const scriptTag = this.props.scriptTag;
		const openingLength = ('<script ').length;

		const scriptAttributesString = scriptTag.substring(openingLength, scriptTag.indexOf('>'));
		const scriptAttributesArray = scriptAttributesString.split(' ')
		const attributesEndIndex = openingLength + scriptAttributesString.length + 1;
		const innerScriptTagContent = scriptTag.substring(attributesEndIndex, scriptTag.indexOf('</script>' || '</ script>'));

		const scriptElement = document.createElement('script');
		scriptAttributesArray.forEach(attributeString => {
			const attributeName = attributeString.split('=')[0]
			const attributeContent = attributeString.substring(`${attributeName}=`.length, attributeString.length).replace(/['"]+/g, '')
			scriptElement[attributeName] = attributeContent;
		});
		scriptElement.type = 'text/javascript';
		scriptElement.async = true;
		scriptElement.innerHTML = innerScriptTagContent;

		document.getElementById(this.props.containerId).appendChild(scriptElement);
	}

	render() {
		const margin = this.props.margin && this.props.margin !== 'none' ? ` margin-${this.props.margin}` : '';

		return (
			<div id={this.props.containerId} className={`generic-script-tag ${margin}`} />
		)
	}
}

export default GenericScriptTag;
