import React from 'react';
import PropTypes from 'prop-types';

import Native from './players/Native';
import YouTube from './players/YouTube';
import Vimeo from './players/Vimeo';

import './index.scss';

const Players = {
  native: Native,
  youtube: YouTube,
  vimeo: Vimeo,
};

const baseName = 'video';

const Video = (props) => {
  const {
    className,
    customAspectRatio,
    player,
  } = props;

  const useAspectRatio = Boolean(player !== 'native' && customAspectRatio);
  const classes = [
    baseName,
    className,
    useAspectRatio && `${baseName}_aspect-ratio`,
  ].filter(Boolean).join(' ');

  const Player = Players[player];

  return (
    <div className={classes} style={useAspectRatio ? { paddingBottom: `${customAspectRatio}%` } : {}}>
      <Player {...props} />
    </div>
  );
};

Video.defaultProps = {
  className: '',
  customAspectRatio: 56.25,
  player: 'native',
};

Video.propTypes = {
  className: PropTypes.string,
  customAspectRatio: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  player: PropTypes.oneOf([
    'vimeo',
    'youtube',
    'native',
  ]),
};


export default Video;
