import React from 'react';
import { useBackgroundVideo } from '../../providers/BackgroundVideoProvider';

import FlexibleContent from '../../utilities/FlexibleContent';

import './index.scss';

const Default = (props) => {
  const { setPageBackgroundInfo } = useBackgroundVideo();

  React.useEffect(() => {
    if (props?.data?.acf?.pageBackgroundVideo?.video?.url) {
      setPageBackgroundInfo(props.data.acf.pageBackgroundVideo);
    } else {
      setPageBackgroundInfo(null);
    }
  }, [setPageBackgroundInfo, props?.data?.acf?.pageBackgroundVideo?.video?.url]);

  if (props.data) {
    return (
      <article className={`${props.slug} default-template`}>
        <FlexibleContent content={props.data.acf.blocks} />
      </article>
    );
  }

  return null;
};

export default Default;
